export const PaymentState = () => {
    return {
        amount: '',
        number : '',
        cardHolderName: '',
        expiry: '',
        cvc: '',
        name: '',
        surname: '',
        phone: '',
        email: '',
        tckn: '',
        studentTckn : "",
        description: '',
        issuer: '',
        focused: '',
        use3D: true,
        campus : '-1',
        school : '',
        class:'',
        paymentSessionId : '',
        redirectUrl : undefined || '',
        referenceNumber : undefined || '',
        studentName : '',
        studentSurname : '',
        payerTckn : '',
        tryCount : 0 ,
        isPayable : false,
        activityCategory: '',
        activityBranch:'',
        loading:false,
        branchPrice:0,
        quarterFactor:0,
        useFreePay:false
      };
  };

  export const ClearGlobal = () => {

    localStorage.setItem("transactionResult" , 0);
    localStorage.setItem("orderReference" , '');
    localStorage.setItem("provisionNumber" , '');
    localStorage.setItem("studentTckn" , '');
    localStorage.setItem("studentName" , '');
    localStorage.setItem("studentSurname" , '');
    localStorage.setItem("educationPeriod" , '');   
    localStorage.setItem("errorMessage" , '');
  }

